<template>
	<div
		class="textarea"
		@dragenter="handleDragEnter"
		@dragover="handleDragEnter"
		@drop="handleDrop"
	>
		<!-- {{ channel }} -->
		<el-input
			class="input_style"
			v-model="from.message"
			:placeholder="aaa()"
			id="myTextarea"
			type="textarea"
			autosize
			resize="none"
			input-style="box-shadow:none;min-height:100px;"
			@input="handleInputChange()"
		></el-input>
		<div class="textarea_bottom">
			<div class="textarea_bottom_left">
				<div class="textarea_bottom_left_item" @click="open(1)">
					<img src="@/assets/image.png" alt="" />
					<span>图片</span>
				</div>
				<div class="textarea_bottom_left_item" @click="open(3)">
					<img src="@/assets/file.png" alt="" />
					<span>文件</span>
				</div>
				<div class="textarea_bottom_left_item" @click="open(2)">
					<img src="@/assets/video.png" alt="" />
					<span>视频</span>
				</div>
				<div class="textarea_bottom_left_item" @click="open(4)">
					<img src="@/assets/mp3.png" alt="" />
					<span>音频</span>
				</div>
				<el-popover
					placement="bottom"
					title="请选择表情"
					:width="300"
					trigger="click"
				>
					<template #reference>
						<div class="textarea_bottom_left_item">
							<img src="@/assets/icon.png" alt="" />
							<span>表情</span>
						</div>
					</template>
					<chatIcon @changeText="getIcon" />
				</el-popover>
			</div>
			<div class="textarea_bottom_right" @click="send" v-if="!con">发送</div>
			<div class="textarea_bottom_right" @click="updateContent" v-else>
				修改
			</div>
		</div>
		<!-- 上传的文件集合 -->
		<div class="textarea_files" v-if="fileList && fileList.length > 0">
			<draggable :list="fileList" animation="300" :sort="true" :force-fallback="true" @end="onEnd" style="width: 100%;display: flex">
				<template #item="{ element }" style="width: 100%;display: flex">
					<div class="textarea_files_item">
						<div class="textarea_files_item_close" @click="deleFile(element)" style="z-index: 9999" >
							x
						</div>
						<img style="height: 70px" :src="element.fullurl" alt="" v-if="!element.isFile" />
						<div v-else-if="isVideo(element.fullurl)">
							<video :controls="false" style="
									width: 68px;
									height: 70px;
									object-fit: contain;
									margin-left: 10px;
								" >
								<source :src="element.fullurl" type="video/mp4" />
							</video>
						</div>
						<span v-else>
							<span v-if="getDocumentImage(element.fullurl) === 1"
								><img
									src="@/assets/Word.png"
									style="width: 50px; height: 50px; margin-left: 10px"
							/></span>
							<span v-if="getDocumentImage(element.fullurl) === 2"
								><img
									src="@/assets/Pdf.png"
									style="width: 50px; height: 50px; margin-left: 10px"
							/></span>
							<span v-if="getDocumentImage(element.fullurl) === 3"
								><img
									src="@/assets/Excel.png"
									style="width: 50px; height: 50px; margin-left: 10px"
							/></span>
							<span v-if="getDocumentImage(element.fullurl) === 5"
								><img
									src="@/assets/audio.png"
									style="width: 50px; height: 50px; margin-left: 10px"
							/></span>
							<span v-if="getDocumentImage(element.fullurl) === 4"
								><img
									src="@/assets/Filedata.png"
									style="width: 50px; height: 50px; margin-left: 10px"
							/></span>
						</span>
					</div>
				</template>
			</draggable>
		</div>
		<!-- 上传图片 -->
		<input
			type="file"
			style="display: none"
			accept=".jpg,.png,.JPG,.JPEG,.PNG,.MP3"
			ref="Img"
			@change="uploads"
			multiple
		/>
		<!-- 上传视频 -->
		<input
			type="file"
			style="display: none"
			accept=".mp4"
			ref="Video"
			@change="uploads"
			multiple
		/>
		<!-- 上传文件 -->
		<input
			type="file"
			style="display: none"
			accept=".word,.xlsx,.doc,.docx,.txt,.PPT,.PDF,.WAR"
			ref="File"
			@change="uploads"
			multiple
		/>
		<!-- 上传音频 -->
		<input
			type="file"
			style="display: none"
			accept=".mp3"
			ref="MP3"
			@change="uploads"
			multiple
		/>

		<Loading v-if="loading" />
	</div>
</template>

<script setup>
import chatIcon from "@/components/chatIcon.vue";
import draggable from 'vuedraggable';
import { upload, plaza_post_add, reprintPost, updatePost } from "@/api/index";
import {
	ref,
	reactive,
	defineProps,
	defineEmits,
	computed,
	onMounted,
	watch,
	getCurrentInstance,
	nextTick
} from "vue";
import { ElMessage } from "element-plus";
import { marked } from "marked";
import Loading from "@/components/Loading.vue";

let props = defineProps({
	channel: String | Number, // 子级栏目id
	typeView: String | Number, // 父级栏目id
	inputtype: String | Number, //类型
	fuid: String | Number, //
	con: Object, //帖子内容
	category_type: String | Number,
});

let emit = defineEmits(["result", "upMask"]);

const Img = ref(null); // 上传图片
const Video = ref(null); // 上传视频
const File = ref(null); // 上传文件
const MP3 = ref(null); // 上传音频
const loading = ref(false); // 上传文件
const file_type = ref([]);

let typeFile = ref(1);
let convalue = ref("");
let contentList = ref([])
let uploadStatus = ref(0);

let from = reactive({
	message: "",
	fileImgs: [],
	fileVideos: [],
	fileMp3: [],
	file: [],
	image: "",
	video: "",
	files: "",
	mp3: "",
});

onMounted(() => {
});

const getDocumentImage = (url) => {
	// 获取文档类型对应的图片路径
	const fileExtension = url.split(".").pop().toLowerCase();
	switch (fileExtension) {
		case "doc":
			return 1;
		case "docx":
			return 1;
		case "pdf":
			return 2;
		case "xls":
			return 3;
		case "xlsx":
			return 3;
		case "mp3":
			return 5;
		default:
			return 4;
	}
};
//拖拽结束的事件
const onEnd = (evt) => {
	const { oldIndex, newIndex } = evt;
	swapElements(contentList.value,oldIndex, newIndex)
	let newArr = []
	contentList.value.forEach((item, index) => {
		newArr.push(fileList.value[item])
	})
	fileList.value = newArr
	const old_list = fileList.value
	from.fileImgs = []
	from.fileVideos = []
	from.file = []
	from.files = ''
	from.fileMp3 = []
	from.image = ''
	from.mp4 = ''
	from.mp3 = ''
	old_list.forEach((item, index) => {
			if (item.typeFile === 1) {
				from.fileImgs.push(item);
				handleUploadSuccess(item.url, "image");
			} else if (item.typeFile === 2) {
				from.fileVideos.push(item);
				handleUploadSuccess(item.url, "mp4");
			} else if (item.typeFile === 3) {
				from.file.push(item);
				handleUploadSuccess(item.url, "attachment");
			} else if (item.typeFile === 4) {
				from.fileMp3.push(item);
				handleUploadSuccess(item.url, "mp3");
			}
	})
};
function swapElements(array, oldIndex, newIndex) {
	// 检查索引是否在数组的有效范围内
	if (oldIndex >= array.length || newIndex >= array.length || oldIndex < 0 || newIndex < 0) {
		console.error('Invalid index provided.'); return;
	}
	// 保存旧位置的元素
	const elementToMove = array[oldIndex];
	// 移除旧位置的元素
	array.splice(oldIndex, 1);
	// 在新位置插入元素
	array.splice(newIndex, 0, elementToMove);
}

// 文件拖拽上传
const handleDrop = async (event) => {
	emit("upMask", 1);
	event.preventDefault();
	// typeFile.value = 3
	const uploadPromises = [];
	for (let i = 0; i < event.dataTransfer.files.length; i++) {
		if (
			event.dataTransfer.files[i].name.includes(".word") ||
			event.dataTransfer.files[i].name.includes(".xlsx") ||
			event.dataTransfer.files[i].name.includes(".doc") ||
			event.dataTransfer.files[i].name.includes(".docx") ||
			event.dataTransfer.files[i].name.includes(".txt") ||
			event.dataTransfer.files[i].name.includes(".ppt") ||
			event.dataTransfer.files[i].name.includes(".pptx") ||
			event.dataTransfer.files[i].name.includes(".pdf") ||
			event.dataTransfer.files[i].name.includes(".war") ||
			event.dataTransfer.files[i].name.includes(".zip") ||
			event.dataTransfer.files[i].name.includes(".rar") ||
			event.dataTransfer.files[i].name.includes(".jpg") ||
			event.dataTransfer.files[i].name.includes(".png") ||
			event.dataTransfer.files[i].name.includes(".webp") ||
			event.dataTransfer.files[i].name.includes(".jpeg") ||
			event.dataTransfer.files[i].name.includes(".mp3") ||
			event.dataTransfer.files[i].name.includes(".gif") ||
			event.dataTransfer.files[i].name.includes(".mp4")
		) {
			const formdate = new FormData();
			formdate.append("file", event.dataTransfer.files[i]);
			if (event.dataTransfer.files[i].type.includes("image")) {
				typeFile.value = 1; // 图片
				file_type.value.push(1);
			} else if (event.dataTransfer.files[i].type.includes("video")) {
				typeFile.value = 2; // 视频
				file_type.value.push(2);
			} else if (event.dataTransfer.files[i].type.includes("audio")) {
				typeFile.value = 4; // 音频
				file_type.value.push(4);
			} else {
				typeFile.value = 3; // 其他文件
				file_type.value.push(3);
			}
			if (event.dataTransfer.files.length == 1) {
				console.log(event.dataTransfer.files.length, "1个文件");
				uploadPromises.push(uploadFile(formdate, 1));
			} else {
				const set = new Set(file_type.value);
				if (set.size === 1) {
					typeFile.value = file_type.value[0];
					uploadPromises.push(uploadFile(formdate, 2));
				} else {
					ElMessage.warning({ message: "只能拖拽同类型的文件" });
				}
			}

			// uploadFile(formdate)
		} else {
			ElMessage.warning({ message: "文件类型不支持上传" });
		}
	}
	await Promise.all(uploadPromises);
	uploadStatus.value = 2;
	emit("upMask", 2);
};
const handleDragEnter = (e) => {
	e.preventDefault(); // 阻止浏览器默认拖拽行为
};

// 实时获取
const fileList = computed(() => {
	let arr = [];
	let data = [
		...from.fileImgs,
		...from.fileVideos,
		...from.file,
		...from.fileMp3,
	];
	data.forEach((item) => {
		if (/\.(doc|docx|word|xlsx|txt|mp4|pdf|mp3|ppt|pptx)$/i.test(item.url)) {
			item.isFile = true;
		} else {
			item.isFile = false;
		}
		arr.push(item);
	});
	contentList.value = data.map((item,index) => index)
	return arr;
});

// 删除文件
const deleFile = (obj) => {
	for (let i = 0; i < from.fileImgs.length; i++) {
		if (from.fileImgs[i].url === obj.url) {
			from.fileImgs.splice(i, 1);
			handleUploadSuccess(obj.url, "image", 0);
			return;
		}
	}
	for (let i = 0; i < from.fileVideos.length; i++) {
		if (from.fileVideos[i].url === obj.url) {
			from.fileVideos.splice(i, 1);
			handleUploadSuccess(obj.url, "mp4", 0);
			return;
		}
	}
	for (let i = 0; i < from.file.length; i++) {
		if (from.file[i].url === obj.url) {
			from.file.splice(i, 1);
			handleUploadSuccess(obj.url, "attachment", 0);
			return;
		}
	}
	for (let i = 0; i < from.fileMp3.length; i++) {
		if (from.fileMp3[i].url === obj.url) {
			from.fileMp3.splice(i, 1);
			handleUploadSuccess(obj.url, "mp3", 0);
			return;
		}
	}
};

const handleTextareaInput = (event) => {
	// Log changes when the textarea value changes
	// if()
};

// 选择表情
const getIcon = (e) => {
	from.message = from.message += e;
};
// 打开选择文件
const open = (type) => {
	typeFile.value = type;
	if (type == 1) {
		Img.value.click();
	} else if (type == 2) {
		Video.value.click();
	} else if (type == 3) {
		File.value.click();
	} else if (type == 4) {
		MP3.value.click();
	}
};

const isVideo = (url) => {
	return url.includes(".mp4");
};

// 获取文件
const uploads = async (event) => {
	emit("upMask", 1);
	console.log(12);
	const uploadPromises = [];
	for (let i = 0; i < event.target.files.length; i++) {
		const formdate = new FormData();
		formdate.append("file", event.target.files[i]);
		uploadPromises.push(uploadFile(formdate, 1));
	}
	try {
		await Promise.all(uploadPromises);
		emit("upMask", 2);
		console.log(23);
	} catch (error) {
		console.error("Error uploading files:", error);
	}
};

let num = ref(1)
// 上传文件
const uploadFile = async (formdata, type) => {
	// const aaa = {
	// 	fullurl: num.value+'.peng',
	// 	isFile: false,
	// 	typeFile: 1,
	// 	url: num.value+'.peng',
	// }
	// from.fileImgs.push(aaa);
	// num.value++
	return new Promise((resolve, reject) => {
		upload(formdata)
			.then((res) => {
				if (res.code === 1) {
					if (type == 2) {
						if (file_type.value[0]) {
							typeFile.value = file_type.value[0];
						} else {
							typeFile.value = typeFile.value;
						}
					}
					res.data.typeFile = typeFile.value;
					if (typeFile.value === 1) {
						from.fileImgs.push(res.data);
						handleUploadSuccess(res.data.url, "image");
					} else if (typeFile.value === 2) {
						from.fileVideos.push(res.data);
						handleUploadSuccess(res.data.url, "mp4");
					} else if (typeFile.value === 3) {
						from.file.push(res.data);
						handleUploadSuccess(res.data.url, "attachment");
					} else if (typeFile.value === 4) {
						from.fileMp3.push(res.data);
						handleUploadSuccess(res.data.url, "mp3");
					}
					file_type.value = [];
				}
				resolve();
			})
			.catch((error) => {
				// reject(error);
				ElMessage({ message: "上传错误请重新上传", type: "error" });
				emit("upMask", 2);
			});
	});
	// 清空input值
	Img.value.value = "";
	Video.value.value = "";
	File.value.value = "";
	MP3.value.value = "";
};
// 发送新闻
const send = () => {
	loading.value = true;
	// if (!from.message) {
	//   ElMessage({message: '内容不能为空', type: 'warning'})
	//   return
	// }
	const htmlContent = from.message;
	// const htmlContent = marked(from.message);
	if (htmlContent.length > 5000) {
		ElMessage({ message: "字数不能超过5000", type: "error" });
		return;
	}
	let category_type = "";
	if (props.typeView == 1) {
		category_type = "";
	} else if (props.typeView == 3 || props.typeView == 19) {
		category_type = "plate";
	} else if (props.typeView == 5) {
		category_type = "news";
	}
	let cid = props.channel == 1 || props.channel == 3 ? 0 : props.channel;
	debugger;
	if (props.inputtype === 4) {
		let ctype = props.category_type;
		if (props.channel == 3) {
			ctype = "";
		}
		reprintPost({
			title: extractText(htmlContent),
			content: htmlContent,
			category_id: cid,
			category_type: ctype,
			file: from.files,
			// accessory: '',
			fileImgs: from.image,
			fileVideos: from.video,
			fileMp3: from.mp3,
			post_keywords: "",
			post_excerpt: "",
			post_source: "",
			post_id: props.fuid,
		}).then((res) => {
			loading.value = false;
			if (res.code === 1) {
				ElMessage({ message: res.msg, type: "success" });
				from.files = "";
				from.image = "";
				from.video = "";
				from.mp3 = "";
				from.message = "";
				from.fileImgs = [];
				from.fileVideos = [];
				file_type.value = [];
				from.file = [];
				emit("result", true);
			} else {
				emit("result", false);
			}
		});
	} else {
		let cid = props.channel == 1 || props.channel == 3 ? 0 : props.channel;
		if (!cid) {
			cid = props.typeView;
		}
		if (cid == 3) {
			cid = 0;
		}
		let catype = props.channel == 1 || props.channel == 3 ? "" : "plate";
		plaza_post_add({
			title: extractText(htmlContent),
			content: htmlContent,
			category_id: cid,
			category_type: catype,
			file: from.files,
			// accessory: '',
			fileImgs: from.image,
			fileVideos: from.video,
			fileMp3: from.mp3,
			post_keywords: "",
			post_excerpt: "",
			post_source: "",
		}).then((res) => {
			loading.value = false;
			if (res.code === 1) {
				ElMessage({ message: res.msg, type: "success" });
				from.files = "";
				from.image = "";
				from.video = "";
				from.mp3 = "";
				from.message = "";
				from.fileImgs = [];
				from.fileVideos = [];
				file_type.value = [];
				from.fileMp3 = [];
				from.file = [];
				emit("result", true);
			} else {
				emit("result", false);
			}
		});
	}
};
const updateContent = () => {
	loading.value = true;
	let array = [];
	for (let i = 0; i < from.fileImgs.length; i++) {
		array.push(from.fileImgs[i]["url"]);
	}
	let finalResultString = array.join(",");

	let array1 = [];
	for (let i = 0; i < from.file.length; i++) {
		array1.push(from.file[i]["url"]);
	}
	let finalResultString1 = array1.join(",");

	let array2 = [];
	for (let i = 0; i < from.fileVideos.length; i++) {
		array2.push(from.fileVideos[i]["url"]);
	}
	let finalResultString2 = array2.join(",");

	let array3 = [];
	for (let i = 0; i < from.fileMp3.length; i++) {
		array3.push(from.fileMp3[i]["url"]);
	}
	let finalResultString3 = array3.join(",");

	const htmlContent = from.message;
	const content = props.con;
	updatePost({
		id: content.id,
		title: extractText(htmlContent),
		content: htmlContent,
		category_type: content.category_type,
		file: finalResultString1,
		fileImgs: finalResultString,
		fileVideos: finalResultString2,
		fileMp3: finalResultString3,
	}).then((res) => {
		loading.value = false;
		if (res.code === 1) {
			ElMessage({ message: res.msg, type: "success" });
			from.files = "";
			from.image = "";
			from.video = "";
			from.mp3 = "";
			from.message = "";
			from.fileImgs = [];
			from.fileVideos = [];
			file_type.value = [];
			from.file = [];
			emit("result", true);
		} else {
			emit("result", false);
		}
	});
};
const extractText = (htmlContent) => {
	const tempDiv = document.createElement("div");
	tempDiv.innerHTML = htmlContent;
	const textContent = tempDiv.textContent || tempDiv.innerText || "";
	let str = textContent.substring(0, 8);
	return str ? str : "无标题";
};

const handleUploadSuccess = (response, fileType, action = 1) => {
	if (fileType === "image") {
		// 图片转换为 Markdown 格式
		const markdownImage = response + ",";
		if (action) {
			// from.message += '\n' + markdownImage;
			from.image += "\n" + markdownImage;
		} else {
			// from.message = from.message.replace(markdownImage, '').trim();
			from.image = from.image.replace(markdownImage, "").trim();
		}
	} else if (fileType === "mp4") {
		// MP4 文件转换为 Markdown 视频链接格式（这只是一个示例，Markdown 标准本身不支持视频嵌入）
		const markdownVideo = response + ",";
		if (action) {
			// from.message += '\n' + markdownVideo;
			from.video += "\n" + markdownVideo;
		} else {
			// from.message = from.message.replace(markdownVideo, '').trim();
			from.video = from.video.replace(markdownVideo, "").trim();
		}
	} else if (fileType === "attachment") {
		// 附件转换为 Markdown 下载链接格式
		const markdownAttachment = response + ",";
		if (action) {
			from.files += "\n" + markdownAttachment;
		} else {
			from.files = from.files.replace(markdownAttachment, "").trim();
		}
	} else if (fileType === "mp3") {
		// 附件转换为 Markdown 下载链接格式
		const markdownMp3 = response + ",";
		if (action) {
			from.mp3 += "\n" + markdownMp3;
		} else {
			from.mp3 = from.mp3.replace(markdownMp3, "").trim();
		}
	}
};

const handleInputChange = () => {
	// 在这里执行你的逻辑，当textarea的值发生改变时触发
};

//根据类型判断显示不同的提示内容
const aaa = () => {
	if (props.inputtype === 3) {
		return "提出您的问题";
	} else if (props.inputtype === 19) {
		return "提出您的问题";
	} else {
		return "欢迎分享您的内容";
	}
};
const formattedText = (text) => {
	return text.replace(/<p>/g, "").replace(/<\/p>/g, "");
};
const setFromContent = () => {
	if ((props.inputtype = 8)) {
		from.fileImgs = props.con.file_img.map((image) => ({
			fullurl: image,
			isFile: false,
			url: image,
		}));
		from.fileVideos = props.con.file_video.map((image) => ({
			fullurl: image,
			isFile: false,
			url: image,
		}));
		from.file = props.con.file_data.map((image) => ({
			fullurl: image,
			isFile: false,
			url: image,
		}));
		from.fileMp3 = props.con.file_audio.map((image) => ({
			fullurl: image,
			isFile: false,
			url: image,
		}));
		from.message = formattedText(props.con.post_content);
		props.inputtype = 7;
	}
};
onMounted(() => {
	if (props.con) {
		setFromContent();
	} else {
		aaa();
	}
});
</script>

<style lang="scss" scoped>
.textarea {
	width: 100%;
	//padding: 12px 12px 0 12px;
	padding: 2px 12px;
	background: #ffffff;
	border: 1px solid #1784fc;
	border-radius: 6px;
	display: flex;
	flex-direction: column;
	box-sizing: border-box;

	textarea::-webkit-scrollbar {
		width: 5px;
	}

	textarea::-webkit-scrollbar-thumb {
		border-radius: 8px;
		box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
		background: #5d6b82;
	}

	textarea {
		width: 100%;
		height: 50px;
		border: none;
		outline: none;
		resize: none;
		font-size: 13px;
		color: #000000;
	}

	.textarea_files {
		width: 100%;
		display: flex;
		align-items: center;

		.textarea_files_item {
			width: 60px;
			height: 75px;
			margin-right: 10px;
			display: flex;
			align-items: center;
			justify-content: center;
			position: relative;

			&:last-child {
				margin: 0 !important;
			}

			span {
				color: #666666;
				font-size: 13px;
				font-weight: 400;
			}

			.textarea_files_item_close {
				position: absolute;
				top: -2px;
				right: -5px;
				width: 15px;
				height: 15px;
				background-color: red;
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 13px;
				color: #fff;
				border-radius: 50%;
				cursor: pointer;
			}

			img {
				width: 100%;
			}
		}
	}

	.textarea_bottom {
		width: 100%;
		height: 30px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		border-top: 1px solid #d8d8d8;

		.textarea_bottom_left {
			flex: 1;
			height: 100%;
			display: flex;
			align-items: center;

			.textarea_bottom_left_item {
				display: flex;
				align-items: center;
				margin-right: 17px;
				cursor: pointer;

				&:last-child {
					margin-right: 0 !important;
				}

				img {
					width: 14px;
					// height: 14px;
					margin-right: 5px;
				}

				span {
					font-size: 14px;
					font-family: Source Han Sans CN;
					font-weight: 400;
					color: #044e94;
				}
			}
		}

		.textarea_bottom_right {
			flex-shrink: 0;
			height: 20px;
			width: 42px;
			line-height: 20px;
			text-align: center;
			background: linear-gradient(0deg, #085eaf, #2791f4);
			border-radius: 10px;
			font-size: 12px;
			font-family: Source Han Sans CN;
			font-weight: 500;
			color: #ffffff;
			cursor: pointer;
		}
	}
}


</style>
<style lang="scss">
.textarea .input_style .el-textarea__inner{
	min-height: 80px !important;
}
</style>
