const lunar = require('chinese-lunar');

export const getCurrentDate = () => {
    let now = new Date();
    let year = now.getFullYear();
    let month = now.getMonth() + 1;
    let day = now.getDate();
    return year + "." + month + "." + day;
}

export const lunarDateToChinese = (lunarDate) => {
    const lunarYearArr = ['零', '一', '二', '三', '四', '五', '六', '七', '八', '九'];
    const lunarMonthArr = ['正', '二', '三', '四', '五', '六', '七', '八', '九', '十', '冬', '腊'];
    const lunarDayArr = ['初', '十', '廿', '三'];
    const year = lunarDate.substring(0, 4);
    const month = parseInt(lunarDate.substring(5, 7));
    const day = parseInt(lunarDate.substring(8,10));

    console.log(year,month,day)

    const chineseYear = year.split('').map(num => lunarYearArr[parseInt(num)]).join('');
    const chineseMonth = lunarMonthArr[month - 1];
    const chineseDay = (day === 10 ? '初十' : lunarDayArr[Math.floor(day / 10)] + lunarYearArr[day % 10]);

    return `${chineseMonth}月${chineseDay}`;
    // return `${chineseYear}年${chineseMonth}月${chineseDay}`;
}

function padZero(num) {
    return num.toString().padStart(2, '0');
}

export const getsolarToLunar = ()=>{
    const date = new Date();
    // 使用 chinese-lunar 将公历转换为农历
    const lunarDate = lunar.solarToLunar(date);
    var nongdate = lunarDate.year + '-' + padZero(lunarDate.month) + '-' + padZero((lunarDate.day - 1))
    return '农历:' + lunarDateToChinese(nongdate)
}

export const getweekstr = () => {
    let now = new Date();
    let week = now.getDay();
    let weekstr = '';
    switch (week) {
        case 0:
            weekstr = '星期日';
            break;
        case 1:
            weekstr = '星期一';
            break;
        case 2:
            weekstr = '星期二';
            break;
        case 3:
            weekstr = '星期三';
            break;
        case 4:
            weekstr = '星期四';
            break;
        case 5:
            weekstr = '星期五';
            break;
        case 6:
            weekstr = '星期六';
            break;
    }
    return weekstr;
}
// 中国标准时间转为 yyyy-mm-dd
export const timestampToTime = (timestamp, type) => {
    let date = null
    if (!timestamp) {
        date = new Date();
    } else {
        date = new Date(timestamp);
    }
    let y = date.getFullYear()
    let m = date.getMonth() + 1
    m = m < 10 ? ('0' + m) : m
    let d = date.getDate()
    d = d < 10 ? ('0' + d) : d
    let h = date.getHours()
    h = h < 10 ? ('0' + h) : h
    let M = date.getMinutes()
    M = M < 10 ? ('0' + M) : M
    let s = date.getSeconds()
    s = s < 10 ? ('0' + s) : s
    if (type === 1) {
        return y + '-' + m + '-' + d;
    } else {
        return y + '-' + m
    }
}

export const toBase64 = (str) => {
    return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function (match, p1) {
        return String.fromCharCode('0x' + p1);
    }));
}

export const fromBase64 = (base64) => {
    return decodeURIComponent(atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
}

export const downloadobj = (obj) => {
    let x = new window.XMLHttpRequest();
    x.open('GET', obj.url, true);
    x.responseType = 'blob';
    x.onload = () => {
        let url = window.URL.createObjectURL(x.response);
        let a = document.createElement('a');
        a.href = url;
        a.download = obj.filename;
        a.click();
    };
    x.send();
}
